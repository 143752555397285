.container {
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
}
.content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 105px);
  height: calc(100vh - 105px);

  > .row {
    align-items: stretch;
    flex: 1;
    height: 100%;
  }
}

.content.content--no-message{

    min-height: calc(100vh - 82px - 23px);
    height: calc(100vh - 82px - 23px);
}
.panel {
  background-color: white;
  padding: 15px;
  // display: inline-block;
  // width: 100%;
 

  &.grey {
    background-color: $light4;
  }
}

.filters {
  // padding: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: $light3;
  overflow-x: scroll;
  min-height: 70px;
  .button {
    margin-right: 10px;
    cursor: pointer;
  }

  div {
    width: auto;
    display: inline-block;
    white-space: nowrap;
  }
}

.pagination-wrapper {
  margin-top: 10px;
  margin-bottom: 15px;
}

.service-panel-col{
    width : 23% !important;
    height: calc(100vh - 18px);
    position: relative;
}

.service-details-col{
    width : 77% !important;
}

.no-padding {
  padding: 0 !important;
}